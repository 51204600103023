import React from "react"
import cls from "classnames"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import "./index.scss"

const Gallery = ({ galleries, className }) => (
  <div className={cls("gallery", className)}>
    <SimpleReactLightbox>
      <SRLWrapper>
        <div className="gallery-container">
          {galleries.map((img) => (
            <img
              className="gallery-image"
              src={img.url}
              key={img.url}
              alt=""
            />
          ))}
        </div>
      </SRLWrapper>
    </SimpleReactLightbox>
  </div>
)

export default Gallery
